<template>
	<h2 style="margin: 0 auto; text-align: center;margin-top: 4%;color:#409EFF ;" >人员信息在线生成</h2>
		<el-row style="margin-top: 4%;">
		<el-col :sm="12" style="margin: 0 auto;">
			<el-form  :inline="true" >
				<el-form-item label="人员姓名">
					<el-input v-model="PIData.name"></el-input>
				</el-form-item> 
				<el-form-item label="姓名拼音">
					<el-input v-model="PIData.phonetic"></el-input>
				</el-form-item> 
				<el-form-item label="身份证号">
					<el-input v-model="PIData.idcard"></el-input>
				</el-form-item> 
				<el-form-item label="手机号码">
					<el-input v-model="PIData.phone"></el-input>
				</el-form-item>
				<el-form-item label="银行卡号">
					<el-input v-model="PIData.bankcard"></el-input>
				</el-form-item >
				<el-form-item label="邮箱地址">
					<el-input v-model="PIData.email"></el-input>
				</el-form-item >
				<el-form-item label="居住地址">
					<el-input v-model="PIData.address"></el-input>
				</el-form-item >
				<el-form-item label="邮编号码">
					<el-input v-model="PIData.zipcode"></el-input>
				</el-form-item >
			</el-form>
			
			<el-col :sm = "24"  style="margin: 0 auto; text-align: center;">
				<el-form-item label="">
					<el-input readonly  type="textarea" :rows="PIData.rows" resize='none'  v-model="PIData.text"></el-input>
				</el-form-item>
			</el-col>
			
			<div style="margin: 0 auto; text-align: center;">
				<el-button id="1001" type="primary" @click="generate()">生成</el-button>
				<el-button  @click="copy(PIData.text)" type="primary" plain>复制</el-button>
			</div>
	</el-col>
	</el-row>
</template>

<script setup>
import {ref} from 'vue'
import pinyin from 'js-pinyin'
import {generate as bancardGenerate} from '../bankcard/bankcard'
import {generate as idcardGenerate} from '../idcard/idcard'
import {generate as mobilephoneGenerate} from '../mobilephone/mobilephone'
import {generate as nameGenerate} from '../name/name'
import {generate as addressGenerate} from '../PI/address'
import {generate as emailGenerate} from '../PI/email'
import {generate as zipcodeGenerate} from '../PI/zipcode'
import {moduleEvent as moduleaction} from '../../common/actionrecord'
import {clipboard as copy} from '../../common/actionrecord'	

	let PIData = ref({
		moduleid: 1001,
		rows: 10,
		name: "",
		phone: "",
		idcard: "",
		bankcard: "",
		phonetic:"",
		email:"",
		zipcode:"",
		text:""
	})
	
//页面预览事件	
moduleaction(PIData.value.moduleid,1);	

//数据生成逻辑
	let generate = () => {
		PIData.value.name=nameGenerate(1,2)[0]
		PIData.value.phonetic=pinyin.getFullChars(PIData.value.name)
		PIData.value.email=emailGenerate(PIData.value.phonetic);
		PIData.value.phone=mobilephoneGenerate(1)[0]
		PIData.value.idcard=idcardGenerate(1)[0]
		PIData.value.bankcard=bancardGenerate(1)[0]
		PIData.value.address=addressGenerate(1)[0]
		PIData.value.zipcode=zipcodeGenerate(1)[0]
		//文本内容
		PIData.value.text=
		 "人员姓名：" + PIData.value.name +"\n"
		+"姓名拼音：" + PIData.value.phonetic + "\n"
		+"身份证号：" + PIData.value.idcard + "\n"
		+"手机号码：" + PIData.value.phone + "\n"
		+"银行卡号：" + PIData.value.bankcard + "\n"
		+"邮箱地址：" + PIData.value.email + "\n"
		+"居住地址：" + PIData.value.address + "\n"
		+"邮编号码：" + PIData.value.zipcode ;
		moduleaction(PIData.value.moduleid,2);
		}
//进入页面，触发数据生成逻辑
generate(); 
</script>
<style>

</style>