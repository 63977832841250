const random = (lower, upper) => {
    return Math.floor(Math.random() * (upper - lower+1)) + lower;
}

const mobilePrefixArray = new Array(
    "622202","622848","622700","622262","621661","622666","622622","622556","622588","622155","622689","622630","622908","621717","622323","622309"
);

export let generate = (size) => {
    let bankcards = new Array(size)
    .fill()
    .map(() => {
             var i = random(0, mobilePrefixArray.length-1);
                 var prefix=mobilePrefixArray[i];
                 for (var j = 0; j < 13; j++) {
                  prefix = prefix + Math.floor(Math.random() * 10);
                }
     return prefix;
    });
    return bankcards;
}