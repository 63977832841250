let random = (lower, upper) => {
    return Math.floor(Math.random() * (upper - lower+1)) + lower;
}

var email_suffixes = new Array(
"@yahoo.com", 
"@gmail.com",
"@sina.com",
"@163.com",
"@126.com",
"@qq.com",
"@hotmail.com"
);

export let generate = (namepinyin) => {
	var suffixes=email_suffixes[random(0,email_suffixes.length-1)];
	var email=namepinyin+suffixes;
    return email;
}