let random = (lower, upper) => {
    return Math.floor(Math.random() * (upper - lower+1)) + lower;
}

var zipcodearray = new Array(
'110102',
'110105',
'110106',
'110107',
'110108',
'110109',
'110111',
'110112',
'110113',
'110114',
'110115',
'110116',
'110117',
'110200',
'110228',
'110229', 
'340100',
'340121',
'130183',
'130184',
'130185',
'130200',
'130201',
'130202',
'130203',
'130204',
'130205',
'130207',
'130208',
'130223',
'130224',
'130225',
'130227',
'130229',
'130230',
'130281',
'130283',
'130300',
'130301',
'130302',
'130303',
'130304',
'130321',
'130322',
'130323',
'130324',
'130400',
'130401',
'130402',
'130403',
'130404',
'130406',
'130421',
'130423',
'130424',
'130425',
'130426',
'130427',
'130428',
'130429',
'130430',
'130431',
'130432'
);

export let generate = (size) => {
	let zipcodes = new Array(size)
	.fill()
	.map(() => {
        var zipcode = zipcodearray[random(0, zipcodearray.length-1)];
        return zipcode;
    });
    return zipcodes;
}