let random = (lower, upper) => {
    return Math.floor(Math.random() * (upper - lower+1)) + lower;
}

var provinces = new Array (
        "北京市",
        "上海市",
        "天津市",
        "重庆市",
        "内蒙古自治区",
        "山西省",
        "河北省",
        "吉林省",
        "江苏省",
        "辽宁省",
        "黑龙江省",
        "安徽省",
        "山东省",
        "浙江省",
        "江西省",
        "福建省",
        "湖南省",
        "湖北省",
        "河南省",
        "广东省",
        "广西壮族自治区",
        "贵州省",
        "海南省",
        "四川省",
        "云南省",
        "陕西省",
        "甘肃省",
        "宁夏回族自治区",
        "青海省",
        "新疆维吾尔自治区",
        "西藏自治区",
        "台湾省",
        "香港特别行政区",
        "澳门特别行政区",
    )
	
var districts = new Array (
        "西夏",
        "永川",
        "秀英",
        "高港",
        "清城",
        "兴山",
        "锡山",
        "清河",
        "龙潭",
        "华龙",
        "海陵",
        "滨城",
        "东丽",
        "高坪",
        "沙湾",
        "平山",
        "城北",
        "海港",
        "沙市",
        "双滦",
        "长寿",
        "山亭",
        "南湖",
        "浔阳",
        "南长",
        "友好",
        "安次",
        "翔安",
        "沈河",
        "魏都",
        "西峰",
        "萧山",
        "金平",
        "沈北新",
        "孝南",
        "上街",
        "城东",
        "牧野",
        "大东",
        "白云",
        "花溪",
        "吉区",
        "新城",
        "怀柔",
        "六枝特",
        "涪城",
        "清浦",
        "南溪",
        "淄川",
        "高明",
        "东城",
        "崇文",
        "朝阳",
        "大兴",
        "房山",
        "门头沟",
        "黄浦",
        "徐汇",
        "静安",
        "普陀",
        "闵行",
        "和平",
        "蓟州",
        "永川",
        "长寿",
        "璧山",
        "合川",
        "梁平",
        "丰都",
        "江北",
    )
	
var cities = new Array(
        "北京",
        "上海",
        "天津",
        "重庆",
        "哈尔滨",
        "长春",
        "沈阳",
        "呼和浩特",
        "石家庄",
        "乌鲁木齐",
        "兰州",
        "西宁",
        "西安",
        "银川",
        "郑州",
        "济南",
        "太原",
        "合肥",
        "武汉",
        "长沙",
        "南京",
        "成都",
        "贵阳",
        "昆明",
        "南宁",
        "拉萨",
        "杭州",
        "南昌",
        "广州",
        "福州",
        "台北",
        "海口",
        "香港",
        "澳门",
        "通辽",
        "兴安盟",
        "太原",
        "辛集",
        "邯郸",
        "沈阳",
        "辽阳",
        "兴城",
        "北镇",
        "阜新",
        "哈尔滨",
        "齐齐哈尔",
        "淮安",
        "张家港",
        "海门",
        "六安",
        "巢湖",
        "马鞍山",
        "永安",
        "宁德",
        "嘉禾",
        "荆门",
        "潜江",
        "大冶",
        "宜都",
        "佛山",
        "深圳",
        "潮州",
        "惠州",
        "汕尾",
        "东莞",
        "梧州",
        "柳州",
        "合山",
        "六盘水",
        "关岭",
    )

var city_suffixes = new Array("市", "县");	

var street_suffixes = new Array("街", "路");

export let generate = (size) => {
    let addresses = new Array(size)
    .fill()
    .map(() => {
        var provincesname = provinces[random(0, provinces.length-1)];
		var citiesname = cities[random(0, cities.length-1)];
		var city_suffixesname = city_suffixes[random(0, city_suffixes.length-1)];
		var districtsname = districts[random(0, districts.length-1)];
		var street_suffixesname = street_suffixes[random(0, street_suffixes.length-1)];
		var address=provincesname + citiesname + city_suffixesname + districtsname + street_suffixesname + random(1, 500) + "号";
		return address;
	   
    });
    return addresses;
}